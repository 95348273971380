import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as R from 'ramda';
import { CambioPaginacion } from './modelo/cambioPaginacion';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent {

  //#region Constantes

    /** Tamaño por defecto para la cantidad de paginas a mostrar en el paginador. */
    public static TAMANIO_PAGINADOR_DEFECTO = 10;

    //#endregion
    
    //#region Propiedades

    /** Evento que emite elementos cuando se genera un cambio en la pagina seleccionada. */
    @Output() onCambioPagina = new EventEmitter<CambioPaginacion>();

    /** Lista de tamaños de pagina disponibles. */
    public tamanioPaginas: number[] = [10, 15, 25, 50, 100, 200, 500];
    
    /** Tamaño de pagina seleccionada. */
    public tamanioPaginaSeleccionada: number;
    
    /** Numero de registro incial de la pagina seleccionada. */
    public registroInicial: number;

    /** Numero de registro final de la pagina seleccionada. */
    public registroFinal: number;
    
    /** Total de registros disponibles. */
    public totalRegistros: number;
    
    /** Total de paginas disponibles con base al tamaño de pagina seleccionada. */
    public totalPaginas: number;
    
    /** Pagina seleccionada. */
    public paginaSeleccionada: number;
    
    /** Lista de paginas a desplegar en el ComboBox. */
    public paginasDesplegadasCombo: number[] = [];
    
    /** Lista de pagina a desplegar en botones. */
    public paginasDesplegadasBoton: number[] = [];    
    
    /** Indica si hay paginas anteriores a la seleccionadas. */
    public hayPaginasAnteriores = false;

    /** Indica si hay paginas posteriores a la seleccionadas. */
    public hayPaginasPosteriores = false;

    //#endregion
    
    //#region Campos
    
    /** Cantidad máxima de botones de pagina a mostrar. */
    private longitudPaginador: number;
    
    /** Cantidad de paginas anteriores y posteriores a mostrar como botones. */
    private paginasMaximasPorLado: number;

    //#endregion
    
    //#region Metodos publicos

    /**
     * Configura y reinicia el paginador. 
     * @param longitudPaginador Cantidad de paginas a mostrar como botones (debe de ser un numero impar).
     */
    public inicializar(longitudPaginador: number) {
        this.longitudPaginador = longitudPaginador;
        this.paginasMaximasPorLado = Number(((longitudPaginador - 1) / 2).toFixed(0));

        //this.tamanioPaginas = tamanioPaginas;
        this.tamanioPaginaSeleccionada = this.tamanioPaginas[0];
        this.paginaSeleccionada = 1;

        this.actualizarTotalRegistros(0);

        this.cambiarPagina();
    }

    /**
     * Emite que hubo un cambio de página.
     */
    public cambiarPagina() {
        this.onCambioPagina.emit(
            {
                paginaSeleccionada: this.paginaSeleccionada,
                tamanioPaginaSeleccionada: this.tamanioPaginaSeleccionada
            });
    }

    /**
     * Actualiza la cantidad de registros disponibles en la consulta y recalcula todas las propiedades.
     * @param totalRegistros
     */
    public actualizarTotalRegistros(totalRegistros: number, paginadorDoble?: boolean) {
        if (paginadorDoble)
            totalRegistros = totalRegistros / 2;

        this.totalRegistros = totalRegistros;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.tamanioPaginaSeleccionada);

        if (paginadorDoble)
            this.totalRegistros = totalRegistros * 2;

        this.calcularDatosPaginador();
    }

    /**
     * Actualiza la cantidad de paginas con base al tamaño de pagina y emite que hubo un cambio en la pagina.
     */
    public recalcularPaginas() {
        this.actualizarTotalRegistros(this.totalRegistros);

        this.cambiarPagina();
    }

    /**
     * Cambia la pagina seleccionada.
     * @param pagina Pagina seleccionada.
     */
    public establecerPaginaSeleccionada(pagina: number) {
        this.paginaSeleccionada = pagina;
        this.cambiarPagina();
    }

    public establecerPaginaSeleccionadaDesdeDrop() {        
        this.cambiarPagina();
    }

    public restablecer()
    {
        this.registroInicial = 0;
        this.registroFinal = 0;
        this.totalRegistros = 0;
        this.paginasDesplegadasBoton = [];
    }

    /**
     * Descrementa en uno la pagina seleccioanda, si es que hay paginas disponibles.
     */
    public decrementarPaginaSeleccionada() {
        this.paginaSeleccionada--;

        if (this.paginaSeleccionada < 1)
            this.paginaSeleccionada = 1;

        this.cambiarPagina();
    }
    
    /**
     * Incrementa en uno la pagina seleccioanda, si es que hay paginas disponibles.
     */
    public incrementarPaginaSeleccionada() {
        this.paginaSeleccionada++;

        if (this.paginaSeleccionada > this.totalPaginas)
            this.paginaSeleccionada = this.totalPaginas;

        this.cambiarPagina();
    }
    
    //#endregion
    
    //#region Metodos privados

    /**
     * Recalcula las propiedades del paginador con base en la pagina seleccionada y el tamaño de pagina seleccionado.
     */
    private calcularDatosPaginador() {        

        if (this.paginaSeleccionada > this.totalPaginas)
            this.paginaSeleccionada = this.totalPaginas;

        if (this.totalPaginas == 0)
            this.paginaSeleccionada = 1;

        this.registroInicial = (this.tamanioPaginaSeleccionada * (this.paginaSeleccionada - 1)) + 1;
        this.registroFinal = this.tamanioPaginaSeleccionada * this.paginaSeleccionada;

        if (this.totalRegistros < this.registroFinal)
            this.registroFinal = this.totalRegistros;

        this.paginasDesplegadasBoton = this.calcularPaginasDesplegar(Number(this.paginaSeleccionada), Number(this.totalPaginas));
        this.paginasDesplegadasCombo = R.range(1, this.totalPaginas + 1);

        this.hayPaginasAnteriores = this.paginaSeleccionada > 1 && this.totalPaginas > 1;
        this.hayPaginasPosteriores = this.paginaSeleccionada < this.totalPaginas && this.totalPaginas > 1;
    }

    /**
     * Genera la cantidad de pagina a mostrar en los botones de paginación  utilizando el limite que se indico al inicializar el componente.
     * @param paginaSeleccionada Pagina seleccionada actualemente.
     * @param paginasTotales Cantidad total de paginas disponibles. 
     * @returns {number[]} Listado de paginas a mostrar en pantalla.
     */
    private calcularPaginasDesplegar = (paginaSeleccionada: number, paginasTotales: number) => {
        let paginas: number[] = [];
        let paginaInicial = 1;
        let paginaFinal = paginasTotales;

        if (paginasTotales > this.longitudPaginador) {
            //Si tanto la primera como la última página estan alejadas más de la mitad de las paginas con respecto a la pagina seleccionada, ambas paginas se colocan a una distancia media
            if (paginaSeleccionada - 1 > this.paginasMaximasPorLado && paginasTotales - paginaSeleccionada > this.paginasMaximasPorLado) {
                paginaInicial = paginaSeleccionada - this.paginasMaximasPorLado;
                paginaFinal = paginaSeleccionada + this.paginasMaximasPorLado;
            }
            //Si solo la primera página esta alejádas más de la mitad de las páginas con respecto a la página seleccionada, la primera pagina se coloca lo más lejos que puede con respecto al máximo menos las paginas siguientes
            else if (paginaSeleccionada - 1 > this.paginasMaximasPorLado) {
                paginaInicial = paginaSeleccionada - (this.paginasMaximasPorLado * 2) + (paginasTotales - paginaSeleccionada);
            }
            //Si solo la última página esta alejádas más de la mitad de las páginas con respecto a la página seleccionada, la última pagina se coloca lo más lejos que puede con respecto al máximo menos las paginas anteriores
            else if (paginasTotales - paginaSeleccionada > this.paginasMaximasPorLado) {
                paginaFinal = paginaSeleccionada + (this.paginasMaximasPorLado * 2) - (paginaSeleccionada - 1);
            }
        }

        for (let i = paginaInicial; i <= paginaFinal; i++)
            paginas.push(i);

        return paginas;
    }

}
