import { Router } from '@angular/router';

export class HttpSession {

    /**
     * 
     */
    constructor() {

    }

    /**
     * 
     * @param key
     * @param object
     */
    public static setAttribute(key: string, object: any) {
        sessionStorage.setItem(key, JSON.stringify(object));
    }

    /**
     * 
     * @param key
     */
    public static getAttribute(key: string): any {
        return JSON.parse(sessionStorage.getItem(key));
    }

    /**
     * 
     * @param key
     */
    public static removeAttribute(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
     * 
     */
    public static clear() {
        sessionStorage.clear();
    }

    /**
     * 
     * @param router
     * @param path
     */
    public static forward(router: Router, path: string): void {
        router.navigate(['/' + path]);
    }

}