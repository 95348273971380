import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { PaginadorComponent } from './paginador/paginador.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports:[CommonModule, PaginadorComponent],
  declarations: [ PaginadorComponent],
  imports: [
    CommonModule,
    FormsModule,  
  ]
})
export class ComunesModule { }
