import { Component, OnInit, AfterViewInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { NotificacionesService } from './../componente/notificaciones.service';
import { Empresa, JsonResponse, Documento, Usuario } from './../modelo';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { HttpSession } from './../libs/HttpSession';
import { NgxUiLoaderService, Loader, SPINNER } from 'ngx-ui-loader';

declare const $: any;
 
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}
 


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, AfterViewInit {


    //ADMINISTRADOR FACTEC
    public ROUTES: RouteInfo[] = [{
            path: '/dashboard',
            title: 'PANEL',
            type: 'link',
            icontype: 'dashboard'
        },{
            path: '/empresa',
            title: 'EMPRESA',
            type: 'sub',
            icontype: 'business',
            collapse: 'empresa',
            children: [
                { path: 'tablero-empresa', title: 'TABLERO OPERADOR', ab: 'TO' },
                { path: 'consulta-empresa', title: 'CONSULTA EMPRESA', ab: 'CE' },
                { path: 'registro-usuario', title: 'REGISTRO USUARIO', ab: 'RU' },
                { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
            ]
        },{
            path: '/acciones',
            title: 'ACCIONES VIVIENDA',
            type: 'sub',
            icontype: 'house',
            collapse: 'acciones',
            children: [ 
                { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' },
                { path: 'validacion-reportes', title: 'VALIDACION REPORTES', ab: 'VR' }
            ]
        }, {
            path: '/reportes',
            title: 'REPORTES',
            type: 'sub',
            icontype: 'insert_chart_outlined',
            collapse: 'reportes',
            children: [
                { path: 'reporte', title: 'REPORTE BI', ab: 'RB' } 
            ]
        }
    ];
 
    public menuItems: any[];    
    public usuario: Usuario;  
    public esRegistroEmpresa: boolean;
    ps: any;
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    constructor(private _router: Router, private ngxUiLoaderService: NgxUiLoaderService) { 
        
        this.usuario = HttpSession.getAttribute('usuariofactec');
        if(this.usuario == null){
            this.esRegistroEmpresa = HttpSession.getAttribute('esRegistroEmpresa');
            if(this.esRegistroEmpresa){
                $("#menuUsuarioFactec").addClass("invisible");  
                this.ROUTES = [];
            }else{
                $("#menuUsuarioFactec").removeClass("invisible");
                this._router.navigate(['/pages/login']); 
            }
        }else{ 

            console.log(this.usuario.idTipoUsuario);
            console.log(this.usuario.idTipoEmpresa);
            console.log(this.usuario.esInterno);
            //ADMINISTRADOR SOFOM 
             if(this.usuario.idTipoUsuario == 1 && !this.usuario.esInterno && this.usuario.idTipoEmpresa == 2 ){

                this.ROUTES = [{
                    path: '/dashboard',
                    title: 'PANEL',
                    type: 'link',
                    icontype: 'dashboard'
                },{
                    path: '/empresa',
                    title: 'EMPRESA',
                    type: 'sub',
                    icontype: 'business',
                    collapse: 'empresa',
                    children: [ 
                        { path: 'consulta-empresa', title: 'CONSULTA EMPRESA', ab: 'CE' },
                        { path: 'registro-usuario', title: 'REGISTRO USUARIO', ab: 'RU' },
                        { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
                    ]
                },{
                    path: '/acciones',
                    title: 'ACCIONES VIVIENDA',
                    type: 'sub',
                    icontype: 'house',
                    collapse: 'acciones',
                    children: [ 
                        { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' }  ,
                        { path: 'registro-acciones', title: 'REGISTRO ACCIONES', ab: 'RA' },
                        { path: 'validacion-reportes', title: 'VALIDACION REPORTES', ab: 'VR' }
                    ]
                } 
            ];
            } 
            //ADMINISTRADOR SUPERVISOR
             if(this.usuario.idTipoUsuario == 1 && !this.usuario.esInterno && this.usuario.idTipoEmpresa == 1){ 
                this.ROUTES = [{
                    path: '/dashboard',
                    title: 'PANEL',
                    type: 'link',
                    icontype: 'dashboard'
                },{
                    path: '/empresa',
                    title: 'EMPRESA',
                    type: 'sub',
                    icontype: 'business',
                    collapse: 'empresa',
                    children: [ 
                        { path: 'consulta-empresa', title: 'CONSULTA EMPRESA', ab: 'CE' },
                        { path: 'registro-usuario', title: 'REGISTRO USUARIO', ab: 'RU' },
                        { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
                    ]
                },{
                    path: '/acciones',
                    title: 'ACCIONES VIVIENDA',
                    type: 'sub',
                    icontype: 'house',
                    collapse: 'acciones',
                    children: [ 
                        { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' },
                        { path: 'validacion-reportes', title: 'VALIDACION REPORTES', ab: 'VR' }
                    ]
                } 
            ];
            } 
            //SUPERVISOR SUPERVISOR
            if (this.usuario.idTipoUsuario == 2 && !this.usuario.esInterno) {
                this.ROUTES = [{
                    path: '/dashboard',
                    title: 'PANEL',
                    type: 'link',
                    icontype: 'dashboard'
                }, {
                    path: '/empresa',
                    title: 'EMPRESA',
                    type: 'sub',
                    icontype: 'business',
                    collapse: 'empresa',
                    children: [
                        { path: 'tablero-empresa', title: 'TABLERO OPERADOR', ab: 'TO' },
                        { path: 'consulta-empresa', title: 'CONSULTA EMPRESA', ab: 'CE' },
                        { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
                    ]
                }, {
                    path: '/acciones',
                    title: 'ACCIONES DE VIVIENDA',
                    type: 'sub',
                    icontype: 'house',
                    collapse: 'acciones',
                    children: [
                        { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' } ,
                        { path: 'validacion-reportes', title: 'VALIDACION REPORTES', ab: 'VR' }
                    ]
                }
                ];
            }
            //SUPERVISOR FACTEC
            if(this.usuario.idTipoUsuario == 2 && this.usuario.esInterno){
                    this.ROUTES = [{
                        path: '/dashboard',
                        title: 'PANEL',
                        type: 'link',
                        icontype: 'dashboard'
                    },{
                        path: '/empresa',
                        title: 'EMPRESA',
                        type: 'sub',
                        icontype: 'business',
                        collapse: 'empresa',
                        children: [ 
                            { path: 'consulta-empresa', title: 'CONSULTA EMPRESA', ab: 'CE' },
                            { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
                        ]
                    },{
                        path: '/acciones',
                        title: 'ACCIONES DE VIVIENDA',
                        type: 'sub',
                        icontype: 'house',
                        collapse: 'acciones',
                        children: [ 
                            { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' }
                        ]
                    }
                ];
            }
             
            //VISOR FACTEC
            if(this.usuario.idTipoUsuario == 3 && this.usuario.esInterno){
                this.ROUTES = [{
                        path: '/dashboard',
                        title: 'PANEL',
                        type: 'link',
                        icontype: 'dashboard'
                    },{
                        path: '/acciones',
                        title: 'ACCIONES DE VIVIENDA',
                        type: 'sub',
                        icontype: 'house',
                        collapse: 'acciones',
                        children: [ 
                            { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' }
                        ]
                    }, {
                        path: '/reportes',
                        title: 'REPORTES',
                        type: 'sub',
                        icontype: 'insert_chart_outlined',
                        collapse: 'reportes',
                        children: [
                            { path: 'reporte', title: 'REPORTE BI', ab: 'RB' } 
                        ]
                    }
                ];
            }


            //ASEGURADORA
            if (this.usuario.idTipoUsuario == 5 && this.usuario.esInterno) {
                this.ROUTES = [{
                    path: '/dashboard',
                    title: 'PANEL',
                    type: 'link',
                    icontype: 'dashboard'
                }, {
                    path: '/acciones',
                    title: 'ACCIONES DE VIVIENDA',
                    type: 'sub',
                    icontype: 'house',
                    collapse: 'acciones',
                    children: [
                        { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' }
                    ]
                }
                ];
            }
            //VISOR NO ES INTERNO
            if(this.usuario.idTipoUsuario == 3 && !this.usuario.esInterno){
                this.ROUTES = [{
                        path: '/dashboard',
                        title: 'PANEL',
                        type: 'link',
                        icontype: 'dashboard'
                    },{
                        path: '/empresa',
                        title: 'EMPRESA',
                        type: 'sub',
                        icontype: 'business',
                        collapse: 'empresa',
                        children: [  
                            { path: 'consulta-usuario', title: 'CONSULTA USUARIO', ab: 'CU' }
                        ]
                    },{
                        path: '/acciones',
                        title: 'ACCIONES DE VIVIENDA',
                        type: 'sub',
                        icontype: 'house',
                        collapse: 'acciones',
                        children: [ 
                            { path: 'consulta-acciones', title: 'CONSULTA ACCIONES', ab: 'CA' }
                        ]
                    }, {
                        path: '/reportes',
                        title: 'REPORTES',
                        type: 'sub',
                        icontype: 'insert_chart_outlined',
                        collapse: 'reportes',
                        children: [
                            { path: 'reporte', title: 'REPORTE BI', ab: 'RB' } 
                        ]
                    }
                ];
            } 
        }
    }


    public cerrarSesion() {

        this.ngxUiLoaderService.start("cerrarSesion");
        HttpSession.removeAttribute('usuariofactec');
        this._router.navigate(['/pages/login']);
        NotificacionesService.mostrarNotificacion('top','right', 'SU SESSION SE CERRO CORRECTAMENTE', 2);
        this.ngxUiLoaderService.stop("cerrarSesion");
    }
    ngAfterViewInit(){
        this.cargarDatosUsuario(); 
    }

    ngOnInit() { 
        this.menuItems = this.ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

         this.cargarDatosUsuario();

        const $sidebar = $('.sidebar');
        $sidebar.attr('data-background-color', 'black');

        this.usuario = HttpSession.getAttribute('usuariofactec');
        if(this.usuario == null){
            this.esRegistroEmpresa = HttpSession.getAttribute('esRegistroEmpresa'); 
            if(this.esRegistroEmpresa){
                $("#menuUsuarioFactec").addClass("invisible");  
                this.ROUTES = [];
            } 
        }

    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    cargarDatosUsuario(){
        

        this.usuario = HttpSession.getAttribute('usuariofactec');
 
        if(this.usuario != null){  
            $("#nombreUsuario").empty();
            $("#nombreUsuario").append((this.usuario.nombre + ' ' + this.usuario.apellidoPaterno).toUpperCase());
            $("#perfilSidebar").empty();
            $("#perfilSidebar").append(this.usuario.perfil.toUpperCase());
            $("#empresaSidebar").empty();
            $("#empresaSidebar").append(this.usuario.nombreCompletoEmpresa.toUpperCase());

        }
         
        
    }
}
