import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { NotificacionesService } from './../componente/notificaciones.service';
import { Empresa, JsonResponse, Documento, Usuario } from './../modelo';

import { HttpSession } from './../libs/HttpSession';
declare const $: any;

@NgModule({
    imports: [ RouterModule, CommonModule ],
    declarations: [ SidebarComponent ],
    exports: [ SidebarComponent ]
})

export class SidebarModule implements OnInit {

    


    ngOnInit() {
 
    }
}
